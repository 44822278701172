


const state = () => ({
    filters:[],
    filtersCopy: []
});
 
const getters = {
    getFilterPayload(state){
        let payload = { filters:{} }
        if(state.filters.length > 0)
            state.filters.forEach(element => {
                payload.filters[element.name] = element.value
            });
        
        return payload
    },
    getOperationValue(state) {
        let operationValue = false;
        if(state.filters.length > 0){
            state.filters.forEach(element => {
                if(element.name == 'operation')
                operationValue = element.value.map((e) => e.value)
            });
        }
        return operationValue;
    },
    getFilterLeadPayload(state){
        let payload = { filters:{} }
        if(state.filters.length > 0)
            state.filters.forEach(element => {
                if(element.name == 'operation')
                    payload.filters['operation_id'] = element.value.map((e) => e.value)
                else if(element.name == 'user')
                    payload.filters['affected_user'] = element.value.map((e) => e.value)
                else
                    payload.filters[element.name] = element.value
            });
        
        return payload
    },
    getFilterRdvPayload(state){
        let payload = { filters:{} }
        if(state.filters.length > 0)
            state.filters.forEach(element => {
                if(element.name == 'operation')
                    payload.filters['operation_id'] = element.value.map((e) => e.value)
                else if(element.name == 'user')
                    payload.filters['user_id'] = element.value.map((e) => e.value)
                else
                    payload.filters[element.name] = element.value
            });
        
        return payload
    }
};
 
const actions = {
    SET_FILTERS({ commit }, value){
        commit("setFilters", value)
    },
    SET_FILTERS_COPY({ commit }, value){
        commit("setFiltersCopy", value)
    },
};
 
const mutations = {
    setFilters(state, filters){
        state.filters = filters
        state.filtersCopy = JSON.parse(JSON.stringify(filters))
    },
    setFiltersCopy(state, filters){
        state.filtersCopy = filters
    },
    
};
 
export default{
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}